import Layout from '../components/Layout';
import ProjectCard from '../components/projects/ProjectCard';
import dragonSlayerLogo from '../assets/dragon-slayer.jpeg';
import mlOpsLogo from '../assets/python.png';
import pokeSearchLogo from '../assets/poke-thumbnail.png';
import kubeflowLogo from '../assets/kubeflow.png';
import '../styles/Projects.css';

export default function Projects() {
  const projects = [
    {
      title: "PokeSearch",
      tagline: "A Pokemon card search engine",
      description: "A web application that allows you to search for Pokemon cards and build decks.",
      image: pokeSearchLogo,
      link: "/poke-search"
    },
    {
      title: "Dragon Slayer",
      tagline: "A game about taming dragons",
      description: "A single-player Android game built with Kotlin and Jetpack Compose. Created as a father-son project, available on the Google Play Store.",
      image: dragonSlayerLogo,
      link: "/dragon-slayer"
    },
    {
      title: "ML Ops - Python",
      tagline: "Test-Driven Development for MLOps",
      description: "Check out this series of blog posts on how to implement TDD for MLOps in Python with Gitlab CI/CD and Google Cloud.",
      image: mlOpsLogo,
      link: "/blog/python-ml-testing-p1"
    },
    {
      title: "Kubeflow",
      tagline: "An ML-Ops approach for Kubeflow Pipelines",
      description: "A series of blog posts on how to implement ML-Ops for Kubeflow Pipelines targetting Google Cloud and Vertex AI.",
      image: kubeflowLogo,
      link: "/blog/kubeflow-p1"
    },
    // Add more projects here as needed
  ];

  return (
    <Layout>
      <div className="projects-container">
        <div className="projects-content">
          <div className="projects-intro">
            <h1 className="projects-intro-title">Our Projects</h1>
            <p className="projects-intro-text">
              Explore our portfolio of innovative projects, from mobile games to web applications.
              Each project represents our commitment to quality and creativity in software development.
            </p>
          </div>
          <div className="projects-grid">
            {projects.map((project) => (
              <ProjectCard key={project.title} project={project} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}