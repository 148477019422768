import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Projects from './pages/Projects';
import Blog from './pages/Blog';
import BlogPost from './components/blog/BlogPost';
import DragonSlayer from './pages/DragonSlayer';
import PokeSearch from './pages/PokeSearch';
import Home from './pages/Home';

function App() {
  return (
    <Router>
      <div className="Home">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/dragon-slayer" element={<DragonSlayer />} />
          <Route path="/poke-search" element={<PokeSearch />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
