import Layout from '../components/Layout';
import '../styles/PokeSearch.css';
import pokeSearchLogo from '../assets/poke-thumbnail.png'; // You'll need to add this asset

export default function PokeSearch() {
    return <Layout>
        <div className="poke-search-container">
            <section className="project-info">
                <img 
                    src={pokeSearchLogo} 
                    alt="PokéSearch Project Logo" 
                    className="project-logo"
                />
                <h1>PokéSearch</h1>
                <p className="tagline">Advanced Pokemon Card Search & Collection Management</p>
                
                <div className="project-links">
                    <a 
                        href="https://poke-search.cards" 
                        className="live-demo-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Visit Live Site
                    </a>
                    {/* Add GitHub/GitLab link if the project is public */}
                </div>

                <div className="description">
                    <p>
                        PokéSearch is a full-stack web application that enables users to search, browse, 
                        and manage Pokemon card collections. Built with modern cloud-native technologies, 
                        it showcases advanced search capabilities and scalable infrastructure.
                    </p>

                    <div className="tech-stack">
                        <h3>Technical Overview</h3>
                        <div className="tech-section">
                            <h4>Frontend</h4>
                            <ul>
                                <li>React-based single-page application</li>
                                <li>User authentication and account management</li>
                                <li>Responsive design for desktop and mobile devices</li>
                            </ul>
                        </div>

                        <div className="tech-section">
                            <h4>Backend & Database</h4>
                            <ul>
                                <li>PostgreSQL with PG Vector extension for similarity search</li>
                                <li>Multimodal embeddings for text and image search</li>
                                <li>RESTful API services</li>
                            </ul>
                        </div>

                        <div className="tech-section">
                            <h4>Infrastructure</h4>
                            <ul>
                                <li>Google Kubernetes Engine (VPC-native)</li>
                                <li>Infrastructure as Code using Terraform</li>
                                <li>GitLab CI/CD pipelines</li>
                                <li>Custom NGINX ingress configuration</li>
                                <li>Automated SSL certificate management</li>
                            </ul>
                        </div>

                        <div className="tech-section">
                            <h4>Key Features</h4>
                            <ul>
                                <li>Advanced card search with text and image similarity</li>
                                <li>Generative AI for card recommendations and insights</li>
                                <li>Personal deck building and management</li>
                                <li>Cloud-native architecture for scalability</li>
                                <li>Automated deployment and infrastructure management</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
}
